import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "svNotFound",
  "title": "404: Denna sida finns inte",
  "button": {
    "text": "Hem",
    "path": "/"
  },
  "endSection": {
    "title": "Läs mer om Zimply",
    "button": {
      "text": "Gå till",
      "path": "about"
    },
    "imageIcon": "/img/bulb.png"
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      